var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"table mx-8"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.species,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_c('v-text-field',{staticClass:"mx-2",attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-container',{staticClass:"d-flex flex-row justify-center",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"createButton"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('edit-species-dialog',{attrs:{"species":_vm.newSpecie},on:{"onSave":function($event){return _vm.createSpecie()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"createButton"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-plus")])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"uploadButton"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-upload")])],1)]}}])},[_c('v-card',{staticClass:"pt-5 mt-5 justify-center"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Upload Species ")])]),_c('v-card-text',[_c('v-form',[_c('v-file-input',{attrs:{"multiple":"","counter":"","show-size":"","chips":"","accept":".csv"},on:{"change":_vm.selectFiles}})],1),_c('v-btn',{on:{"click":_vm.upload}},[_vm._v("Upload")])],1)],1)],1),_c('v-btn',{attrs:{"data-cy":"downloadButton"},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('edit-species-dialog',{attrs:{"species":item},on:{"onSave":function($event){return _vm.updateSpecie(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 action-button",attrs:{"color":"green","data-cy":"editSpecie"}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)}),_c('delete-dialog',{on:{"onConfirm":function($event){return _vm.deleteSpecie(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }

















































import SpeciesDTO from '@/models/SpeciesDTO';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    props: {
        species: SpeciesDTO,
    },
})
export default class EditSpeciesDialog extends Vue {
    dialog = false;
    valid = false;

    save(): void {
        this.$emit('onSave');
        this.dialog = false;
    }
}




















































































































import DeleteDialog from '@/components/admin/DeleteDialog.vue';
import EditSpeciesDialog from '@/components/admin/EditSpeciesDialog.vue';
import SpeciesDTO from '@/models/SpeciesDTO';
import FileService from '@/services/FileService';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        EditSpeciesDialog,
        DeleteDialog,
    },
})
export default class Species extends Vue {
    files: File[] = [];
    uploadProgress = 0;
    headers = [
        { text: 'Cenoteando ID', value: 'id' },
        { text: 'iNaturalist ID', value: 'iNaturalistId' },
        { text: 'Aphia ID', value: 'aphiaId' },
        { text: 'Actions', value: 'action' },
    ];

    item = [];

    search = '';
    newSpecie = new SpeciesDTO();

    species: SpeciesDTO[] = [];

    async created(): Promise<void> {
        await this.$store.dispatch('loading');

        (async () => {
            let generator = RemoteServices.speciesGenerator(30);
            for await (let batch of generator) {
                if (!this.species.length)
                    await this.$store.dispatch('clearLoading');

                this.species.push(...batch);
            }
        })().catch(async (error) => {
            await this.$store.dispatch('error', error);
        });
    }

    async createSpecie(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.createSpecie(this.newSpecie);
        } catch (error) {
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
        this.newSpecie = new SpeciesDTO();
    }

    async updateSpecie(specie: SpeciesDTO): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.updateSpecie(specie);
        } catch (error) {
            // TODO: revert to original value in case of failure
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }

    async deleteSpecie(specie: SpeciesDTO): Promise<void> {
        await RemoteServices.deleteSpecie(specie.id);
        this.species = this.species.filter((s) => s.id != specie.id);
    }

    async download(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            const csv = await RemoteServices.speciesToCsv();
            FileService.download(csv, 'species.csv', 'text/csv');
        } catch (error) {
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }

    selectFiles(files: File[]): void {
        this.uploadProgress = 0;
        this.files = files;
    }

    async upload(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.csvToSpecies(this.files, (event) => {
                this.uploadProgress = Math.round(
                    (100 * event.loaded) / event.total,
                );
            });
        } catch (error) {
            this.uploadProgress = 0;
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }
}
